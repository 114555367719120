// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.level-item{
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto; /* adjust this as needed */
    height: auto; /* adjust this as needed */
    border: 1px solid black;
    box-sizing: border-box;
    padding: 0px 10px;
    text-align: center;
    border-radius: 30px;
    transition: background-color 0.3s ease;
}


.level-item:hover{
    background-color: #ffe54c;
}




`, "",{"version":3,"sources":["webpack://./src/styles/roadmapitem.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW,EAAE,0BAA0B;IACvC,YAAY,EAAE,0BAA0B;IACxC,uBAAuB;IACvB,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,sCAAsC;AAC1C;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.level-item{\n    position: relative;\n    z-index: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: auto; /* adjust this as needed */\n    height: auto; /* adjust this as needed */\n    border: 1px solid black;\n    box-sizing: border-box;\n    padding: 0px 10px;\n    text-align: center;\n    border-radius: 30px;\n    transition: background-color 0.3s ease;\n}\n\n\n.level-item:hover{\n    background-color: #ffe54c;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
